@-webkit-keyframes loading /* Safari and Chrome */ {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes loading {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.loading {
  -webkit-animation: loading 2s linear infinite;
  -moz-animation: loading 2s linear infinite;
  -ms-animation: loading 2s linear infinite;
  -o-animation: loading 2s linear infinite;
  animation: loading 2s linear infinite;
}

.loading-button {
  height: 18px;
  width: 18px;
}

.system-status {
  display: flex;
  align-items: center;
}

.status-indicator {
  height: 7px;
  width: 7px;
  border-radius: 50%;
  margin-right: 5px;
}

.trading-status {
  padding: 3px 7px;
  border-radius: 5px;
}

table {
  th {
    border-right: 1px solid #dce5f0;
    &:last-child {
      border-right: none;
    }
  }
  tr {
    &:last-child {
      td {
        border-bottom: none;
      }
    }
  }
}

.sticky-table-wrapper {
  border-radius: 0 0 7px 7px;
  overflow-x: auto;
  border: 1px solid #dce5f0;
  border-top: none;

  th:first-child,
  td:first-child {
    position: sticky;
    left: 0;
    background-color: white;
    z-index: 1;
  }

  th:nth-child(2),
  td:nth-child(2) {
    position: sticky;
    left: 43px;
    background-color: white;
    z-index: 1;
    border-right: 1px solid #dce5f0;
    white-space: nowrap;
  }

  tfoot {
    td:nth-child(2) {
      border-right: none;
      left: auto;
      background: none;
    }
  }
}
