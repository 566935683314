.menu-icon-active {
  filter: brightness(0) invert(1);
}
.menu-logout-button {
  bottom: 0;
  width: 100%;
  position: absolute;
  display: block;
  border-top: 1px solid #7d899b;
}

.table-container {
  overflow-x: "auto";
  border: "1px solid #7d899b";
  border-top: 0;
  border-radius: "0 0 7px 7px";
}
